.jumbotron {
  margin-bottom: $space-2xl;
  padding: $space-xl 0 $space-2xl;
  border-radius: 0;

  @include media-breakpoint-up(lg) {
    margin-bottom: $space-3xl;
    padding: $space-md 0 $space-3xl;
  }

  &__title {
    margin-bottom: $space-lg;
  }

  &__subtitle {
    color: $gray-600;
  }

  &__image {
    display: flex;
    justify-content: center;
    margin-top: $space-2xl;

    @include media-breakpoint-up(lg) {
      margin-top: $space-sm;
    }
  }

  &__logos {
    margin-top: $space-2xl;
  }

  &__testimonial {
    margin-top: $space-xl;
    text-align: left;

    @include media-breakpoint-up(lg) {
      margin-top: $space-3xl;
    }

    &__author {
      display: block;
      margin: $space-lg 0 $space-2xl;
      font-weight: $font-weight-normal;

      @include media-breakpoint-up(lg) {
        margin-bottom: $space-lg;
      }

      &__name {
        display: block;
        font-size: $font-size-md;
        color: $dark;
      }

      &__position {
        font-size: $font-size-base;
      }
    }

    &__image {
      display: flex;
      justify-content: center;
      align-items: flex-end;

      @include media-breakpoint-up(lg) {
        justify-content: flex-start;
      }
    }
  }

  &__actions {
    margin-top: $space-xl;
  }

  &__form {
    margin-top: $space-2xl;
  }

  &__list {
    margin-top: $space-xl;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    @include media-breakpoint-up(lg) {
      margin-top: $space-3xl;
    }

    &__item:not(:last-child) {
      margin-bottom: $space-lg;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }

    &__icon {
      display: flex;
      align-items: flex-end;
      height: 72px;
    }

    &__title {
      margin: $space-md 0 $space-sm;
    }

    &__desc {
      margin-bottom: 0;
      line-height: $line-height-lg;
    }

    ul {
      margin-top: $space-lg;
    }
  }

  &__vertical-list {
    margin: $space-xl 0;

    @include media-breakpoint-up(lg) {
      margin: $space-2xl 0;
    }

    &__item {
      &:not(:last-child) {
        margin-bottom: $space-lg;
      }
    }

    &__title {
      margin-bottom: $space-2xs;
    }
  }

  &__section {
    margin-top: $space-2xl;

    @include media-breakpoint-up(lg) {
      margin-top: $space-3xl;
    }

    &__title {
      margin-bottom: $space-md;
    }

    &__desc {
      font-weight: $font-weight-normal;
      line-height: $line-height-lg;
      color: $body-color;

      p + p {
        margin-top: $space-lg;
      }
    }
  }

  &__graph {
    position: absolute;
    top: 5%;
    height: 95%;
    max-height: 250px;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  &--careers {
    .container {
      display: flex;
      min-height: 420px;
    }
  }

  &--with-subnav {
    @include media-breakpoint-up(lg) {
      padding-top: $space-2xl;
    }
  }

  &--no-marged {
    margin-bottom: 0;
  }

  &--no-padded {
    padding-bottom: 0;
  }

  &--center {
    text-align: center;
  }

  &--bg {
    background-color: $cream;
  }

  &--home {
    background-color: $orange;

    @include media-breakpoint-up(lg) {
      background: $orange url("/images/jumbotron/home.svg") no-repeat 75% 100%;
      background-size: 75% auto;
    }

    @include media-breakpoint-up(xl) {
      min-height: 560px;
      background-size: 75% auto;
    }

    @include media-breakpoint-up(xxl) {
      min-height: 624px;
      background-size: 1270px auto;
      background-position: 75% 100%;
    }

    .jumbotron__title,
    .jumbotron__subtitle {
      // span {
      //   padding-right: $space-sm;
      //   background-color: $orange;
      //   border-radius: $border-radius-lg;
      // }
    }

    .jumbotron__subtitle {
      font-size: $font-size-xl;
      font-weight: $font-weight-bold;
      color: $white;
    }

    .jumbotron__actions {
      @include media-breakpoint-up(xl) {
        margin-top: $space-2xl;
      }
    }
  }

  &--error {
    overflow: hidden;

    .container {
      position: relative;
    }

    .jumbotron__title {
      @include media-breakpoint-up(lg) {
        margin-top: $space-xl;
      }

      span {
        position: relative;

        &::before,
        &::after {
          position: absolute;

          @include media-breakpoint-up(lg) {
            content: '';
          }
        }

        &::before {
          top: -$space-sm;
          left: -$space-2xl;
          width: 40px;
          height: 33px;
          background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iMzMiIHZpZXdCb3g9IjAgMCA0MCAzMyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyIDEpIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxjaXJjbGUgZmlsbD0iI0Y5MkY5RSIgY3g9IjI5LjQiIGN5PSIyMy40IiByPSI4LjQiLz48ZyBzdHJva2U9IiMxNjE2MTYiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiIHN0cm9rZS13aWR0aD0iMyI+PHBhdGggZD0iTTguNC42djE2LjhNMTYuOCA5SDAiLz48L2c+PC9nPjwvc3ZnPg==');
        }

        &::after {
          right: -$space-3xl;
          bottom: -$space-sm;
          width: 32px;
          height: 32px;
          background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTciIGhlaWdodD0iNTciIHZpZXdCb3g9IjAgMCA1NyA1NyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGZpbGw9IiNGRkQ5NTMiIGQ9Ik0xOSAxOXYzOGgzOFYxOXoiLz48Y2lyY2xlIHN0cm9rZT0iIzE2MTYxNiIgc3Ryb2tlLXdpZHRoPSIzIiB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgMSAzOCAwKSIgY3g9IjE5IiBjeT0iMTkiIHI9IjE3LjUiLz48L2c+PC9zdmc+');
          background-size: 32px auto;
          background-repeat: no-repeat;
        }
      }
    }
  }
}
