.team-list {
  margin-top: $space-3xl;
  padding-left: 0;
  list-style: none;
  text-align: center;

  &__item {
    margin-bottom: $space-xl;

    @include media-breakpoint-up(lg) {
      margin-bottom: $space-2xl;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: $space-3xl;
    }
  }

  &__name {
    margin: 0 0 $space-sm;
  }

  &__desc {
    margin: 0;
    font-size: $font-size-sm;
    line-height: $line-height-lg;
  }
}
