.content-text {
  margin-bottom: $space-xl;

  @include media-breakpoint-up(md) {
    margin-bottom: $space-3xl;
  }

  h2 {
    margin-bottom: $space-sm;
  }

  h3 {
    @extend .h4;
    margin: $space-2xl 0 $space-sm;
  }

  h4 {
    @extend .h5;
    margin: $space-2xl 0 $space-sm;
  }

  p,
  ol,
  ul,
  figure,
  img {
    + h2 {
      margin-top: $space-2xl;

      @include media-breakpoint-up(md) {
        margin-top: $space-3xl;
      }
    }
  }

  p,
  ol,
  ul {
    margin-bottom: $space-lg;
    font-size: $font-size-lg;
    line-height: $line-height-lg;
  }

  ul,
  ol {
    padding-left: $space-lg;
  }

  figure,
  img {
    margin: $space-lg 0;

    @include media-breakpoint-up(md) {
      margin: $space-2xl 0;
    }
  }

  img {
    @extend .img-fluid;
    display: block;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  figure {
    img {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  figcaption {
    margin-top: $space-2xs;
    font-size: $font-size-sm;
  }

  &__actions,
  &__section {
    margin-bottom: $space-2xl;

    @include media-breakpoint-up(md) {
      margin-bottom: $space-3xl;
    }
  }

  &--no-margin {
    margin-bottom: 0;
  }

  .fr-img-wrap {
    text-align: center;
    img {
      width: 1200px;
    }
    span {
      display: block;
      margin-top: -55px;
      font-size: 0.8em;
    }
  }
}
