.filters {
  ul {
    margin-bottom: 0;
  }

  li:not(:last-child) {
    margin-right: $space-md;

    @include media-breakpoint-up(lg) {
      margin-right: $space-xl;
    }
  }

  a {
    display: block;
    padding: $space-xs 0;
    text-decoration: none;
    color: $dark;
    border-bottom: 4px solid transparent;

    &:hover {
      color: $primary;
    }
  }

  .active {
    a {
      font-weight: $font-weight-bold;
      color: $dark;
      border-bottom-color: $dark;
    }
  }

  &--with-border {
    border-bottom: 1px solid $gray-300;

    ul {
      margin-bottom: -1px;
    }
  }
}
