.nav-vertical {
  flex-direction: column;

  &__wrapper {
    padding-bottom: $space-xl;
    margin-bottom: $space-xl;
    border-bottom: 1px solid $gray-300;

    @include media-breakpoint-up(md) {
      position: sticky;
      top: calc(#{$hellobar-height} + #{$space-xl});
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  .nav-item {
    &:not(:last-child) {
      margin-bottom: $space-sm;
    }
  }

  .nav-link {
    @extend .link-dark;
    padding: 0;

    &.active {
      font-weight: $font-weight-bold;
      color: $navbar-light-active-color;
    }
  }
}
