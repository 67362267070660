.values-list {
  margin: $space-3xl 0 $space-3xl;
  padding-left: 0;
  list-style: none;

  @include media-breakpoint-up(lg) {
    margin: $space-4xl 0;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: $space-4xl;
    }
  }

  &__image {
    text-align: center;
    margin-top: $space-lg;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }

  &__area {
    margin-bottom: $space-lg;
    color: $dark;
  }

  &__title {
    margin-bottom: $space-sm;
  }

  &__desc {
    margin-bottom: $space-lg;

    @include media-breakpoint-up(lg) {
      margin-bottom: $space-2xl;
    }
  }
}
