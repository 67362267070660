.dropdown-toggle {
  position: relative;

  i {
    display: inline-block;
    margin-left: $space-3xs;
    transform: translateY(-1px);
  }

  path {
    fill: $navbar-light-color;
    transition: fill 0.25s ease-out;
  }

  &:hover,
  &:focus {
    path {
      fill: $navbar-light-hover-color;
    }
  }
}

.dropdown-menu {
  &::after {
    position: absolute;
    top: 0;
    left: 50%;
    margin-top: -$space-2xs;
    border: $space-2xs solid transparent;
    border-top: 0;
    border-bottom-color: $white;
    transform: translateX(-50%);
    content: '';
  }
}
