label {
  color: $dark;
}

#contact-form-default-hs {
  /*max-height: calc(100% - 115px); */
  overflow: hidden;
}

.hide-hs {
  max-height: calc(100% - 115px);
  overflow: hidden;
}

.form-control {
  margin-bottom: $space-md;

  &--width-auto {
    width: auto;
  }

  &--with-shadow {
    box-shadow: $box-shadow-lg;
  }
}

.form {
  &--search {
    position: relative;

    .form-control {
      padding-right: $space-2xl;
    }

    .btn {
      position: absolute;
      top: 0;
      right: 0;
      width: 60px;
      height: 60px;
      padding: 0;
      box-shadow: none;
      border-radius: $border-radius-lg;
    }
  }

  &--block {
    display: flex;
    width: 100%;
    box-shadow: $box-shadow;

    .form-control {
      margin-bottom: 0;
      border: $white;
      border-radius: $border-radius 0 0 $border-radius;

      &:focus {
        box-shadow: none;
      }
    }

    .btn {
      white-space: nowrap;
      border-radius: 0 $border-radius $border-radius 0;
      padding-right: $space-md;
      padding-left: $space-md;
      box-shadow: none;
    }
  }
}

select.form-control {
  appearance: none;
  padding-right: 32px;
  text-overflow: "";
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTS4xNzYgMS41MzhhLjU2NS41NjUgMCAwMTAtLjgyTC43NDEuMTdhLjYxMi42MTIgMCAwMS44NDggMEw3IDUuNTA0IDEyLjQxLjE3YS42MTIuNjEyIDAgMDEuODQ5IDBsLjU2NS41NDdhLjU2NS41NjUgMCAwMTAgLjgyTDcuNDIzIDcuODI5YS42MDkuNjA5IDAgMDEtLjg0NiAwbC02LjQtNi4yOTF6IiBmaWxsPSIjMTYxNjE2IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=");
  background-repeat: no-repeat;
  background-position: right 12px center;

  &::-ms-expand {
    display: none;
  }

  &.form-control-sm {
    background-size: 10px auto;
    padding-right: $space-lg;
    background-position: right 8px center;
  }

  // Added by Nexudus team
  option {
    padding: 10px;
  }
}
