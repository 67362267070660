.pricing {
  &__footer {
    margin-top: $space-sm;

    @include media-breakpoint-up(lg) {
      margin-top: $space-xl;
    }

    p {
      margin: $space-sm 0 0;
    }

    &__users-currency {
      @include media-breakpoint-up(lg) {
        display: flex;
      }

      div {
        order: 2;
      }

      p {
        margin: 0 $space-xl 0 0;

        @include media-breakpoint-up(xl) {
          margin-right: $space-5xl;
        }
      }
    }
  }
}
