.faq-list {
  margin: $space-md 0 0;
  padding-left: 0;
  list-style: none;

  @include media-breakpoint-up(lg) {
    margin-top: $space-2xl;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: $space-xl;
    }
  }

  &__question {
    margin-bottom: $space-sm;
  }

  &__answer {
    line-height: $line-height-lg;
  }

  &__read-more {
    margin-top: $space-xl;

    b {
      color: $dark;
    }
  }
}
