@include sticky-footer(".site-header", ".site-content", ".site-footer");

.site-footer {
  padding: $space-2xl 0 $space-lg;
  color: $gray-500;
  background-color: $dark;

  ul {
    padding-left: 0;
    list-style: none;
  }




  li {
    font-size: $font-size-md;

    &:not(:last-child) {
      margin-bottom: $space-xs;
    }
  }

  .container a {
    @extend .link-tertiary;
    color: $white;
  }



  &__logo {
    display: inline-block;
    margin-bottom: $space-sm;

    path {
      fill: $white;
    }
  }


  &__title {
    margin: $space-lg 0 $space-sm;
    color: $white;

    @include media-breakpoint-up(sm) {
      margin-bottom: $space-lg;
    }

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }

    @include media-breakpoint-between(md, lg) {
      &--newsletter {
        margin-top: $space-lg;
      }
    }
  }

  .form-control {
    border: $white;
  }

  &__foot {
    margin-top: $space-2xl;
    padding-top: $space-lg;
    border-top: 1px solid $gray-500;

    @include media-breakpoint-up(md) {
      margin-top: $space-4xl;
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: space-between;
    }

    p {
      margin-bottom: 0;
    }
  }

  &__social {
    margin-top: -$space-3xs;

    li:not(:last-child) {
      margin-right: $space-md;
    }

    svg {
      max-width: $space-md;
      max-height: $space-md;
    }

    path {
      fill: $gray-500;
    }
  }

  &__copy {
    a {
      text-decoration: underline;
    }
  }

  &__gold-medal {
    display: inline-block;
    width: 100px;
  }

  &__gold-medal-first {
    margin-top: 20px;
  }

  &__gold-medal-second {
    margin-top: 20px;
    width: 100px;
    display: inline-block;
    margin-left: 10px
  }

}