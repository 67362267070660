.certificate {
  &__image {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: $space-2xl;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

  &__subtitle {
    margin-bottom: $space-lg;
  }

  &__actions {
    margin-top: $space-xl;

    @include media-breakpoint-up(lg) {
      margin-top: $space-2xl;
    }
  }
}
