.prize {
  margin: $space-xl 0;

  @include media-breakpoint-up(lg) {
    margin: $space-2xl 0;
  }

  &__cover {
    margin-right: $space-lg;
  }

  b {
    display: block;
    margin: $space-3xs 0 $space-2xs;
    color: $dark;
  }

  a {
    box-shadow: none !important;
  }

  .gold-medal {
    width: 60px;
    vertical-align: middle;
  }
}
