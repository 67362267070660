.map {
  margin-bottom: $space-xl;
  padding-bottom: $space-xl;

  @include media-breakpoint-up(lg) {
    padding-bottom: $space-3xl;
  }

  &__col {
    &:not(:last-child) {
      margin-bottom: $space-lg;

      @include media-breakpoint-only(lg) {
        margin-bottom: 0;
      }

      @include media-breakpoint-up(xl) {
        margin-bottom: $space-xl;
      }
    }
  }

  &__box {
    @include media-breakpoint-only(lg) {
      height: 100%;
    }

    &__title {
      font-weight: $font-weight-normal;
    }

    span {
      display: block;
      margin-bottom: $space-3xs;
      font-weight: $font-weight-normal;
      color: $orange;
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: $space-lg 0 $space-sm;

    @include media-breakpoint-up(lg) {
      margin-top: $space-xl;
    }

    @include media-breakpoint-up(xl) {
      margin-top: 0;
    }
  }
}
