$knob-size: calc(#{$space-md} + 3px);

.slider {
  appearance: none;
  width: 100%;
  height: $space-2xs;
  background: $gray-200;
  outline: none;
  border-radius: $space-2xs;

  &::-webkit-slider-thumb {
    appearance: none;
    width: $knob-size;
    height: $knob-size;
    background: $dark;
    border: 3px solid $white;
    border-radius: 50%;
    box-shadow: $box-shadow-sm;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: $knob-size;
    height: $knob-size;
    background: $dark;
    border: 3px solid $white;
    border-radius: 50%;
    box-shadow: $box-shadow-sm;
    cursor: pointer;
  }

  &::-ms-fill-lower {
    background: $gray-200;
  }

  &::-ms-fill-upper {
    background: pink;
  }
}

.slider:hover {
  opacity: 1;
}
