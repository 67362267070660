a {
  transition: color 0.25s ease-out;
}

.link {
  &-secondary {
    @include link-variant($link-secondary-color, $link-secondary-decoration, $link-secondary-hover-color, $link-secondary-hover-decoration);
  }

  &-tertiary {
    @include link-variant($link-tertiary-color, $link-tertiary-decoration, $link-tertiary-hover-color, $link-tertiary-hover-decoration);
  }

  &-dark {
    @include link-variant($link-dark-color, $link-dark-decoration, $link-dark-hover-color, $link-dark-hover-decoration);
  }

  &-light {
    @include link-variant($link-light-color, $link-light-decoration, $link-light-hover-color, $link-light-hover-decoration);
  }

  &-underline {
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
