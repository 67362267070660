.video-player {
  position: relative;

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    width: $space-xl;
    height: $space-xl;
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTQiIGhlaWdodD0iNTQiIHZpZXdCb3g9IjAgMCA1NCA1NCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxIDEpIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxyZWN0IHN0cm9rZT0iIzE2MTYxNiIgc3Ryb2tlLXdpZHRoPSIyIiBmaWxsPSIjRkZGIiB3aWR0aD0iNTIiIGhlaWdodD0iNTIiIHJ4PSIyNiIvPjxwYXRoIGQ9Ik0xOC41IDE5LjczNnYxMi41MjhhMiAyIDAgMDAyLjg5NCAxLjc4OWwxMi41MjgtNi4yNjRhMiAyIDAgMDAwLTMuNTc4bC0xMi41MjgtNi4yNjRhMiAyIDAgMDAtMi44OTQgMS43OXoiIGZpbGw9IiMxNjE2MTYiLz48L2c+PC9zdmc+') no-repeat center center;
    border: 2px solid $gray-900;
    border-radius: 50%;
    box-shadow: $box-shadow;
    transform: translate(-50%, -50%);
    content: '';
  }
}
