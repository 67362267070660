.consultant {
  &__header {
    margin: $space-lg 0 $space-sm;

    @include media-breakpoint-up(lg) {
      margin-bottom: $space-xl;
    }
  }

  &__cover {
    @include aspect-ratio(1, 1);
    display: block;
    min-width: $space-4xl;
    margin-right: $space-lg;

    @include media-breakpoint-up(lg) {
      min-width: $space-5xl;
    }
  }

  &__title {
    svg {
      width: auto;
      height: 36px;
      transform: translateY(-6px);
    }
  }

  &__subtitle {
    margin-bottom: $space-xs;
    color: $body-color;
  }

  &__details {
    margin-bottom: 0;

    li {
      margin-bottom: $space-xs;
    }
  }

  &__body {
    p {
      &:first-child {
        @extend .text-xl;
      }
    }
  }

  &__actions {
    margin-top: $space-xl;

    @include media-breakpoint-up(lg) {
      margin-top: $space-2xl;
    }
  }
}
