.blog-post {
  &__cover {
    overflow: hidden;
    max-height: 600px;

    img {
      object-fit: cover;
      width: 100%;
    }
  }

  &__inner {
    padding-top: $space-lg;
    background-color: $white;

    @include media-breakpoint-up(sm) {
      position: relative;
      z-index: 1;
      margin-top: -74px;
    }

    @include media-breakpoint-only(sm) {
      padding-right: $space-md;
      padding-left: $space-md;
    }

    @include media-breakpoint-up(md) {
      margin-top: -143px;
      padding-right: 0;
      padding-left: 0;
    }
  }

  &__header {
    text-align: center;
  }

  &__categories {
    display: inline-block;
    margin-right: $space-2xs;
  }

  &__tags {
    display: inline-block;
    margin-right: $space-md;
  }

  &__title {
    margin: $space-sm 0;

    @include media-breakpoint-up(md) {
      margin: $space-lg 0;
    }
  }
}
