.solution-areas-list {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;

  &__item {
    padding: $space-2xl 0 $space-xl;

    @include media-breakpoint-up(md) {
      padding: $space-3xl 0 $space-2xl;
    }

    @include media-breakpoint-up(lg) {
      padding: $space-4xl 0 $space-3xl;
    }

    &--bg {
      background-color: $cream;
    }
  }

  &__image {
    display: flex;
    align-items: flex-start;
    margin-left: -$space-md;

    &--to-right {
      margin-left: 0;
    }
  }

  &__title {
    margin: $space-md 0;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }

    @include media-breakpoint-up(xl) {
      margin-top: $space-md;
    }
  }

  &__body {
    p:last-child {
      margin-bottom: 0;
    }
  }
}
