.badge {
  height: 21px;
  line-height: 1.2;
  text-decoration: none;
  text-transform: uppercase;
  transform: translateY(-1px);

  &-orange {
    color: $white;
  }

  &-lilac {
    color: $primary;
    background-color: $lilac;
  }
}
