.apps-list {
  margin-bottom: $space-3xl;
  padding-left: 0;
  list-style: none;

  @include media-breakpoint-up(lg) {
    margin-bottom: $space-4xl;
  }

  &__item {
    margin-bottom: $space-2xl;

    &:not(:last-child) {
      padding-bottom: $space-2xl;
      border-bottom: 1px solid $gray-300;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: $space-3xl;

      &:not(:last-child) {
        padding-bottom: $space-3xl;
      }
    }
  }

  &__icon {
    @include aspect-ratio(1, 1);
    display: block;
    width: $space-2xl;
    margin-right: $space-md;
    background-color: $primary;
    border-radius: 20px;

    @include media-breakpoint-up(md) {
      width: $space-3xl;
    }

    @include media-breakpoint-up(lg) {
      width: $space-4xl;
    }
  }

  &__categories {
    @include ellipsis(28px, 1);
    margin-bottom: $space-sm;
  }

  &__title {
    margin: $space-2xs 0;
  }

  &__desc {
    margin-bottom: 0;
  }

  &__features {
    margin: $space-sm 0 $space-lg;
    padding-left: $space-md;
    list-style: disc;

    @include media-breakpoint-up(lg) {
      margin: $space-xl 0;
    }
  }

  &__download {
    > li {
      &:not(:last-child) {
        margin-right: $space-sm;
      }
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: $space-xl;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }
}
