// Ellipsis and Ellipsis Max
// Variation of http://codepen.io/martinwolf/pen/qlFdp
@mixin ellipsis($height, $lines, $max-height: false) {
  display: block;
  display: -webkit-box;

  /* autoprefixer: off */
  -webkit-box-orient: vertical;

  // Normal or max height
  @if $max-height {
    max-height: $height;
  }
  @else {
    height: $height;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: $lines; // lines to show
}

// Ellipsis with max-height in block
@mixin ellipsis-max-height($height, $lines) {
  @include ellipsis($height, $lines, true);
}
