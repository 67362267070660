.banner {
  background: #ffe9c7;
  color: black;
  padding-top: 16px;
  padding-bottom: 2px;
  text-align: center;
}

.banner a {
  color: #0ccced;
  background-color: #ffe9c7;
}

.download-button {
  margin-left: 20px;
}