.solutions-list {
  margin-top: $space-sm;
  padding-left: 0;
  list-style: none;

  @include media-breakpoint-up(lg) {
    margin-top: $space-xl;
  }

  &__item {
    margin-top: $space-lg;
  }

  &__icon {
    display: flex;
    align-items: flex-end;
    height: 36px;
  }

  &__title {
    margin: $space-xs 0 $space-sm;
  }
}
