.pricing-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: $space-lg;

  @include media-breakpoint-up(lg) {
    height: 100%;
  }

  &__header {
    margin-bottom: $space-xl;
    padding-bottom: $space-xl;
    text-align: center;
    border-bottom: 1px solid $gray-200;

    &--with-slider {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  &__title {
    margin-bottom: 0;
    font-weight: $font-weight-normal;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 82px;
    }
  }

  &__price {
    margin: $space-md 0 $space-sm;
    color: $dark;

    @include media-breakpoint-up(lg) {
      height: 85px;
      margin-bottom: $space-md;
    }

    &__currency {
      display: inline-block;
      font-weight: $font-weight-bold;
      transform: translateY(-20px);
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__freq {
    margin: 0;
    color: $dark;
  }

  &__conditions {
    margin: 0;
  }

  &__slider {
    position: relative;
    height: 61px;
    margin-top: calc(#{$space-xl} - #{$space-xs});

    &__count {
      position: absolute;
      top: -14px;
      left: 0;
      font-size: $font-size-sm;

      & + & {
        right: 0;
        left: auto;
      }
    }

    & + & {
      margin-top: 0;
    }
  }

  &__tooltip {
    bottom: 120%;
    left: 50%;
    transform: translateX(-50%);

    .tooltip-inner {
      min-width: 240px;
    }
  }

  &__list {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    color: $dark;

    &__item {
      position: relative;
      padding-left: $space-lg;

      &:not(:last-child) {
        margin-bottom: $space-sm;
      }
    }

    &__icon {
      position: absolute;
      top: $space-3xs;
      left: 0;
    }

    path {
      fill: $body-color;
    }
  }

  &__integrations {
    margin: $space-xl 0 $space-lg;
    font-size: $font-size-base;
  }

  &__footer {
    margin-top: $space-xl;
    text-align: center;

    .btn {
      @include media-breakpoint-up(lg) {
        min-width: 100%;
      }

      @include media-breakpoint-up(xl) {
        min-width: 280px;
      }
    }
  }
}
