.case-studies-list {
  margin-bottom: $space-3xl;
  padding-left: 0;

  @include media-breakpoint-up(lg) {
    margin-bottom: $space-4xl;
  }

  &__item {
    margin-bottom: $space-2xl;

    @include media-breakpoint-up(lg) {
      margin-bottom: $space-3xl;
    }
  }

  &__cover {
    @include aspect-ratio(16, 9);
    display: block;
    overflow: hidden;
    margin-bottom: $space-sm;
    background-color: $primary;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  &__title {
    margin-bottom: $space-sm;
  }

  &__actions {
    margin-top: $space-sm;

    @include media-breakpoint-up(xl) {
      margin-top: $space-xl;
    }
  }
}
