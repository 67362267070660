@include media-breakpoint-down(sm) {
  .scrollable {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;

    &__wrapper {
      position: relative;
    }

    &::before,
    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 1;
      width: $space-sm;
      height: 100%;
      content: '';
    }

    &::before {
      left: 0;
      background-image: linear-gradient(to left, transparent, $white);
    }

    &::after {
      right: 0;
      background-image: linear-gradient(to right, transparent, $white);
    }

    &__child {
      display: flex;
      flex: 0 0 auto;
    }
  }
}
