@mixin link-variant($color, $decoration, $color-hover, $hover-decoration) {
  text-decoration: $decoration;
  color: $color;

  &:hover,
  &:focus {
    text-decoration: $hover-decoration;
    color: $color-hover;
  }
}
