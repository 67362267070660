// Prevent the header from covering the anchor
// (https://css-tricks.com/hash-tag-links-padding/#article-header-id-1)

.anchor {
  &::before {
    display: block;
    visibility: hidden;
    pointer-events: none;
    height: calc(#{$hellobar-height} + #{$space-xs});
    margin-top: calc(-#{$hellobar-height} - #{$space-xs});
    content: '';
  }
}
