.press-resources-list {
  margin-bottom: $space-xl;
  padding-left: 0;
  list-style: none;

  @include media-breakpoint-up(lg) {
    margin-bottom: $space-4xl;
  }

  &__item {
    margin-bottom: $space-xl;
  }

  &__cover {
    @include aspect-ratio(16, 9);
    display: block;
    overflow: hidden;
    background-color: $primary;
  }

  &__title {
    margin: $space-lg 0 $space-sm;
  }

  &__actions {
    margin-top: $space-md;
    font-weight: $font-weight-bold;
  }
}
