.training-list {
  margin-bottom: $space-xl;
  padding-left: 0;
  list-style: none;

  &__item {
    margin-bottom: $space-lg;

    @include media-breakpoint-up(sm) {
      margin-bottom: $space-xl;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  &__cover {
    @include aspect-ratio(111, 50);
    display: block;
    overflow: hidden;
    background-color: $primary;
  }

  &__title {
    margin: $space-md 0 $space-2xs;
  }

  &__desc,
  &__availability {
    font-size: $font-size-sm;
  }

  &__desc {
    margin-bottom: $space-xs;
    line-height: $line-height-lg;
  }
}
