.logos-list {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  text-align: center;

  &__item {
    display: inline-block;
    margin: 0 $space-sm $space-md;

    @include media-breakpoint-up(lg) {
      margin-right: $space-md;
      margin-left: $space-md;
    }
  }

  &__name {
    margin-top: $space-md;
  }

  &--spaced {
    justify-content: space-around;

    .logos-list__item {
      margin: 0;
    }
  }

  &--marged {
    margin-top: $space-xl;
  }
}
