.integrations {
  padding: $space-2xl 0 $space-xs;
  background-color: $cream;

  @include media-breakpoint-up(lg) {
    padding-bottom: $space-2xl;
  }

  &__title {
    margin-bottom: $space-xl;
  }
}
