$circle-border-width: $space-xs;
$circle-size: 42px;
$line-width: 2px;

.affiliates-how-list {
  position: relative;
  margin-bottom: $space-2xl;
  padding-left: 0;
  list-style: none;

  @include media-breakpoint-up(lg) {
    margin-bottom: $space-4xl;
  }

  &::after {
    position: absolute;
    right: 0;
    bottom: -$space-lg;
    width: 57px;
    height: 57px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTciIGhlaWdodD0iNTciIHZpZXdCb3g9IjAgMCA1NyA1NyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGZpbGw9IiNGRkQ5NTMiIGQ9Ik0xOSAxOXYzOGgzOFYxOXoiLz48Y2lyY2xlIHN0cm9rZT0iIzE2MTYxNiIgc3Ryb2tlLXdpZHRoPSIzIiB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgMSAzOCAwKSIgY3g9IjE5IiBjeT0iMTkiIHI9IjE3LjUiLz48L2c+PC9zdmc+");
    content: "";
  }

  &__item {
    position: relative;
    padding-bottom: $space-lg;

    &:not(:last-child) {
      &::before {
        position: absolute;
        top: 0;
        left: calc(24px - (#{$line-width} * 2));
        width: $line-width;
        height: 100%;
        background-color: $dark;
        content: "";
      }
    }
  }

  &__number {
    box-sizing: content-box;
    position: relative;
    z-index: 1;
    width: $circle-size;
    height: $circle-size;
    margin: (-$circle-border-width) $space-md 0 (-$circle-border-width);
    line-height: $circle-size;
    text-align: center;
    color: $white;
    background-color: $dark;
    border: $circle-border-width solid $blue-light;
    border-radius: 50%;
  }

  &__title {
    margin-bottom: $space-sm;
  }

  &__desc {
    margin-bottom: 0;
  }
}
