.solutions-examples-list {
  margin-bottom: $space-sm;
  padding-left: 0;
  list-style: none;

  @include media-breakpoint-up(md) {
    margin-top: $space-xl;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: $space-xl;
  }

  &__item {
    margin-bottom: $space-lg;
  }

  &__inner {
    @include media-breakpoint-up(lg) {
      padding: 0 $space-md;
    }

    @include media-breakpoint-up(xl) {
      padding: 0 $space-lg;
    }
  }

  &__icon {
    width: $space-2xl;
    text-align: center;
    margin-right: $space-sm;

    @include media-breakpoint-up(md) {
      margin-right: $space-md;
    }

    @include media-breakpoint-up(lg) {
      margin-right: $space-lg;
    }
  }

  &__title {
    margin-bottom: $space-2xs;
  }

  &__desc {
    line-height: $line-height-lg;
  }
}
