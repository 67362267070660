.integrations-list {
  margin-bottom: $space-xl;
  padding-left: 0;
  list-style: none;

  &__item {
    margin-bottom: $space-lg;
  }

  &__box {
    height: 100%;
    display: flex;
    flex-direction: column;

    img {
      max-width: 60px;
    }

    @include media-breakpoint-up(lg) {
      min-height: 333px;
    }

    &--ghost {
      @include media-breakpoint-up(sm) {
        padding-top: 81px;
      }
    }
  }

  &__title {
    margin: $space-md 0 $space-sm;

  }

  &__titlemanaged {
    margin: $space-md 0 $space-sm;
    font-size: $font-size-sm;
    color: #3F3F3F
  }


  &__desc {
    margin: 0;
    font-size: $font-size-sm;
  }

  &__desc {
    margin: 0;
    font-size: $font-size-sm;
  }

  &__search {
    display: none;
  }

  &__actions {
    margin-top: $space-md;
    font-weight: $font-weight-bold;
  }

  &__link {
    margin: auto 0 0;
    font-weight: 700;
    padding: 10px 0 0;
  }

  &__powered {
    margin: $space-md $space-sm;
    font-size: $font-size-sm;
    color: #3F3F3F
  }

}