@mixin sticky-footer($header, $main, $footer) {
  body {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  #{$header},
  #{$footer} {
    flex: none;
  }

  #{$main} {
    flex: 1 0 auto;
    width: 100%;
  }
}
