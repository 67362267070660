.cta {
  padding: $space-2xl 0;
  text-align: center;

  @include media-breakpoint-up(lg) {
    padding: $space-3xl 0;
  }

  &__title {
    margin-bottom: $space-md;
  }

  &__subtitle {
    margin-bottom: 0;
    font-weight: $font-weight-normal;
  }

  &__actions {
    margin-top: $space-lg;
    a {
      margin: 0 5px;
    }
  }

  &__list {
    margin-bottom: 0;
    font-weight: $font-weight-bold;

    &__item {
      &:not(:last-child) {
        margin-bottom: $space-xs;
      }
    }
  }

  &--blue {
    background-color: $blue-capri;
  }

  &--yellow {
    background-color: $yellow;
  }

  &--landing {
    text-align: left;
  }

  &--api {
    padding: $space-2xl 0;
    text-align: left;
    background-color: $blue-light;

    @include media-breakpoint-up(lg) {
      padding: $space-4xl 0;
    }

    &__title {
      margin-bottom: $space-sm;
    }

    &__subtitle {
      margin-bottom: $space-sm;
      line-height: $line-height-lg;
      color: $body-color;
    }

    &__image {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-bottom: $space-xl;

      @include media-breakpoint-up(md) {
        justify-content: flex-end;
      }

      img {
        @include media-breakpoint-up(lg) {
          margin-right: $space-lg;
        }
      }
    }
  }

  &--apps {
    padding: $space-2xl 0;

    @include media-breakpoint-up(md) {
      text-align: left;
    }

    @include media-breakpoint-up(lg) {
      padding: $space-4xl 0;
    }

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__title {
      margin-bottom: $space-sm;
    }

    &__image {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-top: $space-xl;

      @include media-breakpoint-up(md) {
        justify-content: flex-end;
      }
    }
  }

  &--integrations {
    overflow: hidden;
    padding: $space-3xl 0;
    text-align: left;

    @include media-breakpoint-up(lg) {
      padding: $space-4xl 0;
    }

    .container {
      position: relative;
      min-height: 404px;
    }

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__title {
      margin-bottom: $space-sm;
    }

    &__image {
      margin-top: $space-xl;

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }

      img {
        max-width: 100%;

        @include media-breakpoint-up(md) {
          max-width: initial;
        }
      }
    }
  }
}
