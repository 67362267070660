.blog-post-featured {
  margin-bottom: $space-2xl;
  padding: $space-2xl 0;
  background-color: $cream;

  @include media-breakpoint-up(lg) {
    margin-bottom: $space-3xl;
    padding: $space-3xl 0;
  }

  &__cover {
    display: block;
    overflow: hidden;
    margin-bottom: $space-sm;
    background-color: $primary;
  }

  &__categories {
    margin-bottom: $space-md;
  }

  &__title {
    margin-bottom: $space-lg;

    a {
      @extend .link-dark;
    }
  }

  &__body {
    color: $dark;

    @include media-breakpoint-up(md) {
      @include ellipsis(114px, 4);
    }

    @include media-breakpoint-up(lg) {
      height: 130px;
    }
  }

  &__metadata {
    margin-top: $space-lg;
    font-size: $font-size-base;
  }
}
