h1,
.h1,
h2,
.h2 {
  letter-spacing: -0.0125em;
}

h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  letter-spacing: 0;
}

.display-1 {
  color: $headings-color;
}

.text-md,
.text-lg,
.text-xl {
  font-weight: $font-weight-normal;
  letter-spacing: normal;
  line-height: $line-height-lg;
}

.text-md {
  @include media-breakpoint-up(lg) {
    font-size: $font-size-md;
  }
}

.text-lg {
  font-size: $font-size-md;

  @include media-breakpoint-up(lg) {
    font-size: $font-size-lg;
  }
}

.text-xl {
  font-size: $font-size-xl;

  @include media-breakpoint-up(lg) {
    font-size: $font-size-2xl;
  }
}
