.reviews-list {
  margin: $space-2xl 0;
  padding-left: 0;
  list-style: none;
  color: $dark;

  @include media-breakpoint-up(lg) {
    margin: 0 0 $space-4xl;
  }

  &__item {
    padding: $space-lg;
    background-color: $cream;
    border-radius: $border-radius;

    &:not(:last-child) {
      margin-bottom: $space-lg;
    }

    @include media-breakpoint-up(lg) {
      padding: $space-xl;

      &:not(:last-child) {
        margin-bottom: $space-xl;
      }
    }
  }

  &__blockquote {
    margin-bottom: $space-sm;
  }

  &__author {
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
  }
}
