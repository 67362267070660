$section-padding-y: $space-2xl;
$section-padding-y-up-lg: $space-3xl;

.section {
  margin: $section-padding-y 0;

  @include media-breakpoint-up(lg) {
    margin: $section-padding-y-up-lg 0;
  }

  &__title {
    margin-bottom: $space-sm;

    &__filigree {
      display: inline-block;
      position: relative;
      padding: 0 $space-2xl;

      @include media-breakpoint-up(lg) {
        padding: 0 $space-3xl;
      }

      &::before,
      &::after {
        position: absolute;
        width: 40px;
        height: 33px;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iMzMiIHZpZXdCb3g9IjAgMCA0MCAzMyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyIDEpIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxjaXJjbGUgZmlsbD0iI0Y5MkY5RSIgY3g9IjI5LjQiIGN5PSIyMy40IiByPSI4LjQiLz48ZyBzdHJva2U9IiMxNjE2MTYiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiIHN0cm9rZS13aWR0aD0iMyI+PHBhdGggZD0iTTguNC42djE2LjhNMTYuOCA5SDAiLz48L2c+PC9nPjwvc3ZnPg==');
        content: '';
      }

      &::before {
        top: $space-md;
        left: 0;
        transform: scaleY(-1);
      }

      &::after {
        top: 0;
        right: 0;
        transform: scaleX(-1);
      }
    }
  }

  &__subtitle {
    margin-bottom: $space-xl;
    font-weight: $font-weight-base;
    letter-spacing: normal;
    line-height: $line-height-lg;
    color: $gray-600;
  }

  &__read-more {
    margin-top: $space-sm;
    font-weight: $font-weight-bold;
  }

  &__actions {
    text-align: center;
  }

  &--bg {
    margin: 0;
    padding: $section-padding-y 0 $space-xl;

    @include media-breakpoint-up(lg) {
      padding: $section-padding-y-up-lg 0 $space-2xl;
    }

    .section__actions {
      margin-bottom: $space-sm;

      @include media-breakpoint-up(lg) {
        margin-bottom: $space-2xl;
      }
    }
  }

  &--blue {
    @extend .section--bg;
    background-color: $blue-light;
  }

  &--blue-baby-tears {
    @extend .section--bg;
    background-color: $blue-baby-tears;
  }

  &--cream {
    @extend .section--bg;
    background-color: $cream;
  }
}
