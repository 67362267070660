.latest-news-list {
  margin-top: $space-2xl;
  margin-bottom: $space-xl;
  padding-left: 0;
  list-style: none;

  @include media-breakpoint-up(md) {
    margin-top: 0;
    margin-bottom: $space-sm;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: $space-2xl;
  }

  &__item {
    margin-bottom: $space-lg;

    &:not(:last-child) {
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }
  }

  &__inner {
    padding: $space-xs 0 $space-xs $space-lg;
    border-left: 1px solid $gray-200;

    @include media-breakpoint-up(md) {
      min-height: 200px;
    }
  }

  &__title {
    margin-bottom: $space-2xs;
    line-height: $line-height-lg;
  }
}
