.videos-list {
  margin: $space-lg 0 0;
  padding-left: 0;
  list-style: none;

  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: $space-md;

      @include media-breakpoint-up(xl) {
        margin-bottom: $space-lg;
      }
    }
  }

  &__video {
    display: block;
    position: relative;
    text-decoration: none;
    cursor: pointer;

    &::before {
      background-color: $white;
      border-radius: $border-radius;
    }

    &:hover,
    &:focus,
    &.active {
      &::before {
        position: absolute;
        top: -$space-xs;
        right: -$space-xs;
        bottom: -$space-xs;
        left: -$space-xs;
        z-index: -1;
        background-color: $gray-100;
        content: '';
      }
    }

    &:hover,
    &:focus {
      .video-player::after {
        opacity: .75;
        transition: all 0.25s ease-out;
      }
    }

    &.active {
      .videos-list__title {
        color: $primary;
      }

      .video-player::after {
        opacity: 1;
      }
    }
  }

  &__cover {
    @include aspect-ratio(16, 9);
    display: block;
    overflow: hidden;
    min-width: $space-4xl;
    margin-right: $space-sm;
    background-color: $primary;
    border-radius: $border-radius;

    @include media-breakpoint-up(xl) {
      min-width: $space-5xl;
      margin-right: $space-lg;
    }
  }

  &__title {
    margin-top: $space-2xs;
  }
}
