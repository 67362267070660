.affiliates-list {
  margin-top: $space-2xl;
  margin-bottom: $space-lg;
  padding-left: 0;
  list-style: none;

  &__item {
    margin-bottom: $space-lg;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  &__box {
    height: 100%;
  }

  &__title {
    margin: $space-md 0 $space-sm;
  }

  &__desc {
    margin: 0;
  }

  &__turnover {
    margin-top: $space-xs;
  }

  &__monthly {
    font-size: $font-size-sm;
  }

  &__percentage {
    display: block;
    margin: $space-3xs 0;
  }

  &__duration {
    color: $dark;
  }
}
