.newsletter {
  padding: $space-2xl 0;
  color: $dark;
  background-color: $blue-capri;

  @include media-breakpoint-up(lg) {
    padding: $space-4xl 0;
  }

  &__title {
    margin-bottom: $space-md;
  }

  &__subtitle {
    margin-bottom: 0;
    color: $dark;
  }

  &__form {
    display: flex;
    align-self: center;
    margin-top: $space-lg;
  }
}
