.hr {
  margin: $space-2xl 0;

  @include media-breakpoint-up(lg) {
    margin: $space-3xl 0;
  }

  .section + & {
    @include media-breakpoint-down(lg) {
      margin-top: -$space-lg;
    }
  }
}
