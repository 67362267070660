.subnavbar {
  position: relative;
  height: $subnavbar-height;
  background-color: $white;
  box-shadow: 0 0 0 transparent;
  border-bottom: 1px solid $gray-300;
  transition: box-shadow 0.25s ease-out;

  @include media-breakpoint-up(lg) {
    margin-top: -$space-sm;
    position: relative;
    z-index: 2;
  }

  .container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }

  &--scrolled {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zindex-sticky;
    width: 100%;
    margin-top: 0;
    box-shadow: $box-shadow;

    &.subnavbar--with-hellobar {
      margin-top: $hellobar-height;
    }
  }
}

.subnavbar-nav {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: $subnavbar-height;
  left: 0;
  z-index: 1;
  width: 100%;
  margin-bottom: 0;
  padding: $space-md $space-xs;
  list-style: none;
  background-color: $white;
  box-shadow: $box-shadow;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    position: static;
    padding: 0;
    background-color: transparent;
    box-shadow: none;

    &:not(.show) {
      display: flex;
    }
  }

  .nav-item {
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      margin-left: $space-sm;
      min-width: auto;
    }

    &#js-subnavbar-pricing-link {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &--action {
      display: flex;
      align-items: center;

      a {
        width: 100%;
        padding: 8px;
        margin-bottom: $space-xs;

        @include media-breakpoint-up(lg) {
          width: auto;
          padding: 0.375rem 2.5rem;
          margin: 0;
        }
      }
    }

    .dropdown-toggle i {
      transform: translateY(-3px);
    }

    .nav-link {
      font-weight: $font-weight-bold;
      color: $navbar-light-color;
      padding-top: 14px;

      @include media-breakpoint-up(lg) {
        margin-left: $space-sm;
        line-height: $subnavbar-height - 12px;
        border-bottom: 4px solid transparent;

        &:hover,
        &:focus {
          color: $navbar-light-hover-color;
        }
      }
    }

    &.active {
      .nav-link {
        color: $navbar-light-active-color;

        @include media-breakpoint-up(lg) {
          color: $navbar-light-color;
          border-bottom-color: $dark;
        }
      }
    }
  }

  .dropdown-menu {
    &.show {
      display: flex;
    }

    @include media-breakpoint-down(md) {
      position: static;
      float: none;
      display: block;
      margin-bottom: $space-xs;
      padding: 0 0 $space-sm;
      border-bottom: 1px solid $gray-300;
      border-radius: 0;
      box-shadow: none;

      &::after {
        display: none;
      }
    }

    @include media-breakpoint-up(lg) {
      top: initial !important;
      left: initial !important;
      transform: translateX(
        calc(-50% + (#{$dropdown-padding-y} * 16) + #{$space-2xs})
      ) !important;
      flex-wrap: wrap !important;
      width: 900px !important;

      &:after {
        left: 34%;
      }
    }

    @include media-breakpoint-up(xl) {
      top: initial !important;
      left: initial !important;
      transform: translateX(
        calc(-50% + (#{$dropdown-padding-y} * 4) + #{$space-2xs})
      ) !important;
      width: 720px !important;

      &:after {
        left: 50%;
      }
    }

    .bullets {
      margin: 0 0 0 35px;
      font-size: 0.8rem;
      padding: 0 0 20px;
      .checks-list {
        color: #161616;
      }
      a {
        color: #161616;
        text-decoration: underline;
      }
    }

    .action {
      margin: auto 0 0 calc(64px - 0.75rem);
    }

    .heading {
      color: #161616;
      &:hover,
      &:focus {
        text-decoration: none;
        color: $primary;
      }
    }

    .dropdown-item--subnav {
      width: 100%;
      border-right: 1px solid #e2e1df;
      padding: 0.75rem 1.5rem;
      display: flex;
      flex-direction: column;
      @include media-breakpoint-up(lg) {
        width: 300px;
      }
      @include media-breakpoint-up(xl) {
        width: 360px;
      }
      .media {
        padding: 0 0 20px;
      }
      span,
      b {
        white-space: normal;
      }
      @include media-breakpoint-down(md) {
        padding: $space-2xs 0;
        border-right: 0;
      }
      &:last-child {
        border-right: 0;
      }

      i.icon {
        width: $space-xl;
        height: $space-xl;
        margin-right: $space-sm;

        path,
        rect {
          stroke: $orange;
          transition: all 0.25s ease-out;
        }
        &:hover,
        &:focus {
          path,
          rect {
            stroke: $primary;
          }
        }
      }
    }

    b,
    span {
      display: block;
    }

    span {
      margin-top: $space-3xs;
      font-size: $font-size-sm;
      color: $body-color;
    }
  }
}

.subnavbar-brand {
  margin-right: $space-sm;
  flex-shrink: 0;
  font-size: $font-size-lg;
  color: $dark;

  &:hover,
  &:focus {
    color: $dark;
    text-decoration: none;
  }

  &__icon {
    margin-right: $space-sm;

    svg {
      width: $space-lg;
      height: auto;
    }

    path,
    rect {
      stroke: $orange;
    }
  }

  &__text {
    display: inline-block;
    transform: translateY(2px);
  }
}

.subnavbar-toggler {
  display: inline-block;
  margin: -$space-sm;
  padding: $space-sm;
  transform: rotate(180deg) translateY(-2px);
  transition: all 0.25s ease-out;

  &.collapsed {
    transform: rotate(0) translateY(-2px);
  }

  svg {
    width: $space-sm;
    height: auto;

    path {
      fill: $dark;
    }
  }
}
