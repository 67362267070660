.i-space {
  &--right {
    margin-right: $space-3xs;
    &--sm { margin-right: 2px; }
    &--lg { margin-right: $space-2xs; }
    &--xl { margin-right: $space-sm; }
  }

  &--left {
    margin-left: $space-3xs;
    &--sm { margin-left: 2px; }
    &--lg { margin-left: $space-2xs; }
    &--xl { margin-left: $space-sm; }
  }
}
