.consultants-list {
  margin-bottom: $space-lg;
  padding-left: 0;
  font-size: $font-size-sm;
  list-style: none;

  @include media-breakpoint-up(md) {
    margin-bottom: $space-xl;
  }

  &__item:not(:last-child) {
    margin-bottom: $space-lg;
  }

  &__box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__inner {
    @include media-breakpoint-up(md) {
      min-height: 360px;
    }

    @include media-breakpoint-up(lg) {
      min-height: 400px;
    }
  }

  &__cover {
    @include aspect-ratio(1, 1);
    display: block;
    overflow: hidden;
    max-width: 48px;
  }

  &__title {
    @include ellipsis(50px, 2, true);
    margin: $space-lg 0 $space-xs;
  }

  &__details {
    li {
      margin-bottom: $space-3xs;
    }
  }

  &__desc {
    @include ellipsis(90px, 4);
    margin: $space-md 0 $space-lg;
    line-height: $line-height-mm;
  }

  &__footer {
    min-height: 28px;

    .list-inline-item {
      &:not(:last-child) {
        margin-right: $space-md;
      }
    }
  }
}