@keyframes float {
  0% { transform: rotate(0deg) translate(-50%) rotate(0deg); }
  to { transform: rotate(360deg) translate(-50%) rotate(-360deg); }
}

.graph {
  position: absolute;
  animation: float 4s linear infinite;
  will-change: transform;

  &--icon {
    width: 26px;
  }

  &:nth-child(odd) {
    animation-direction: reverse;
  }

  &--left {
    &:nth-child(1) {
      animation-duration: 4s;
      top: 18%;
      left: 14%;
    }

    &:nth-child(2) {
      animation-duration: 5s;
      top: 76%;
      left: 19%;
    }

    &:nth-child(3) {
      animation-duration: 5.5s;
      top: 50%;
      left: 42%;
    }

    &:nth-child(4) {
      animation-duration: 6s;
      top: 54%;
      right: 7%;
    }

    &:nth-child(5) {
      animation-duration: 7s;
      top: 22%;
      left: 55%;
    }

    &:nth-child(6) {
      animation-duration: 4.5s;
      top: 87%;
      left: 49%;
    }
  }

  &--right {
    &:nth-child(1) {
      animation-duration: 6.5s;
      top: 22%;
      left: 55%;
    }

    &:nth-child(2) {
      animation-duration: 6s;
      top: 54%;
      right: 12%;
    }

    &:nth-child(3) {
      animation-duration: 5.5s;
      top: 76%;
      left: 9%;
    }

    &:nth-child(4) {
      animation-duration: 5s;
      top: 50%;
      left: 42%;
    }

    &:nth-child(5) {
      animation-duration: 4s;
      top: 18%;
      left: 10%;
    }

    &:nth-child(6) {
      animation-duration: 4.5s;
      top: 84%;
      left: 39%;
    }
  }
}
