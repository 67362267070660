.core-features-list {
  margin-top: $space-xl;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;

  @include media-breakpoint-up(lg) {
    margin-top: $space-2xl;
  }

  &__item {
    margin-bottom: $space-lg;

    @include media-breakpoint-up(md) {
      margin-bottom: $space-2xl;
    }
  }

  &__title {
    margin-bottom: $space-lg;
  }
}
