.blog-posts-list {
  margin-bottom: $space-lg;
  padding-left: 0;
  list-style: none;

  &__item {
    margin-bottom: $space-xl;

    @include media-breakpoint-up(lg) {
      margin-bottom: $space-2xl;
    }

    @include media-breakpoint-up(md) {
      &--no-margin-up-md {
        margin-bottom: 0;
      }
    }
  }

  &__inner {
    padding-bottom: $space-sm;
    border-bottom: 1px solid $gray-300;
  }

  &__cover {
    //@include aspect-ratio(16, 9);
    display: block;
    margin-bottom: $space-sm;
    overflow: hidden;
    background-color: $primary;
  }

  &__categories {
    @include ellipsis(28px, 1);
    margin-bottom: $space-sm;
  }

  &__title {
    @include ellipsis(57px, 2);
    margin-bottom: $space-sm;

    a {
      @extend .link-dark;
    }
  }

  &__body {
    @include ellipsis(74px, 3);
    color: $dark;
  }

  &--margin-top {
    margin-top: $space-lg;
  }
}
