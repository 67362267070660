.videos {
  &__subtitle {
    margin: $space-xl 0 $space-lg;

    @include media-breakpoint-up(lg) {
      margin: $space-2xl 0 $space-xl;
    }
  }

  &__video {
    overflow: hidden;
    border-radius: $border-radius;
  }
}

.responsive-youtube {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
