.blog-post-author {
  margin-bottom: $space-2xl;
  padding: $space-lg;
  color: $dark;
  background-color: $pink;
  border-radius: $border-radius;

  @include media-breakpoint-up(md) {
    margin-bottom: $space-3xl;
  }

  p:last-child {
    margin-bottom: 0;
  }

  &__image {
    margin-right: $space-md;
  }

  &__title {
    margin-bottom:  $space-3xs;

    span {
      display: block;
      margin: $space-3xs 0;
      font-size: $font-size-sm;
      font-weight: $font-weight-normal;
      letter-spacing: normal;
    }

    a {
      @extend .link-dark;
    }
  }
}
