@mixin center-fixed {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#popup {
  position: fixed; 
  display: none;
  z-index: 9;
  width: 100%;
  
  .popup--dark-bg {
    @include center-fixed;
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 0.5;
  }

  .data-quadrant-container, .popup-container {
    @include center-fixed;
    background-color: #ffffff;
    padding: 4rem;
    border-radius: 1%;

    @media only screen and (max-width: 600px) {
      width: 75%;
      max-width: 75%;
      padding: 1rem;
    }
  }

  .data-quadrant-container {
    max-width: 45%;
  }

  .hubspot-form-container {
    max-height: 60vh;
    overflow-y: scroll;
  }

  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    cursor: pointer;

    @media only screen and (max-width: 600px) {
      top: -10%;
    }
  }

  &.show {
    display: block;
  }
}
  