.case-studies-grid-list {
  margin-top: $space-xl;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;

  &__item {
    margin-bottom: $space-sm;

    &:not(:last-child) {
      margin-bottom: $space-2xl;
    }

    @include media-breakpoint-up(md) {
      &:not(:last-child) {
        margin-bottom: $space-sm;
      }
    }
  }

  &__cover {
    @include aspect-ratio(16, 9);
    display: block;
    overflow: hidden;
    background-color: $primary;
  }

  &__title {
    margin: $space-lg 0 $space-sm;
  }

  &__actions {
    margin-top: $space-lg;
  }
}
