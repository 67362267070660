.consultants-list-simple {
  margin: $space-lg 0 $space-xl;
  padding-left: 0;
  font-size: $font-size-sm;
  list-style: none;

  &__item {
    padding-bottom: $space-md;

    &:not(:last-child) {
      margin-bottom: $space-md;
      border-bottom: 1px solid $gray-300;
    }
  }

  &__cover {
    @include aspect-ratio(1, 1);
    display: block;
    width: 48px;
    margin-right: $space-xs;
  }

  &__title {
    margin-bottom: $space-3xs;
  }

  &__details {
    margin-bottom: $space-2xs;
    padding-left: 0;

    li {
      display: inline;

      &:not(:last-child) {
        &::after {
          margin: 0 $space-3xs;
          content: '·';
        }
      }
    }
  }

  &__footer {
    .list-inline-item {
      svg {
        width: auto;
        height: 22px;
      }

      &:not(:last-child) {
        margin-right: $space-sm;

        svg {
          height: 20px;
        }
      }
    }
  }
}
