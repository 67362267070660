.hellobar {
  display: flex;
  align-items: center;
  position: fixed;
  z-index: $zindex-fixed + 1;
  width: 100%;
  height: $hellobar-height;
  padding: $space-3xs 0;
  font-size: $font-size-sm;
  line-height: 1;
  text-align: center;
  color: $white;
  background-color: $dark;

  @include media-breakpoint-up(lg) {
    padding-top: $space-xs;
    padding-bottom: $space-xs;
    font-size: $font-size-base;
    line-height: $line-height-base;
  }
}
