.navbar {
  position: relative;
  z-index: 1;
  height: $navbar-height;
  padding: 0;
  transition: height 0.25s ease-in-out;

  @include media-breakpoint-up(lg) {
    position: static;
    height: $navbar-height-up-lg;
  }

  &--scrolled {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zindex-sticky;
    width: 100%;
    margin-top: 0;
    box-shadow: $box-shadow;
    background-color: $white;

    @include media-breakpoint-up(lg) {
      height: $navbar-height-sticky;
    }
  }

  &--with-hellobar {
    margin-top: $hellobar-height;
  }

  &--cream {
    background-color: $cream;
  }

  &--home:not(&--scrolled) {
    background-color: $orange;
  }

  @include media-breakpoint-down(md) {
    &--opened {
      background-color: $white;

      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 3px;
        background-color: $white;
        content: "";
      }
    }
  }

  &.navbar-light .navbar-nav .show>.nav-link {
    color: $navbar-light-hover-color;
  }

  &.navbar-expand-lg .nav-link {
    font-weight: $font-weight-bold;

    @include media-breakpoint-up(lg) {
      padding-right: $space-sm;
      padding-left: $space-sm;
    }
  }

  .dropdown-menu {
    &.show {
      display: flex;
    }

    @include media-breakpoint-down(md) {
      display: block;
      margin-bottom: $space-xs;
      padding: $space-sm 0 0;
      border-bottom: 1px solid $gray-300;
      border-radius: 0;
      box-shadow: none;

      &::after {
        display: none;
      }
    }

    @include media-breakpoint-up(lg) {
      transform: translateX(calc(-50% + (#{$dropdown-padding-y} * 16) + #{$space-2xs}));
      flex-wrap: wrap;
      width: 900px;

      &:after {
        left: 34%;
      }
    }

    @include media-breakpoint-up(xl) {
      transform: translateX(calc(-50% + (#{$dropdown-padding-y} * 4) + #{$space-2xs}));
      width: 720px;

      &:after {
        left: 50%;
      }
    }

    .bullets {
      margin: 0 0 0 35px;
      font-size: 0.8rem;
      padding: 0 0 20px;

      .checks-list {
        color: #161616;
      }

      a {
        color: #161616;
        text-decoration: underline;
      }
    }

    .action {
      margin: auto 0 0 calc(64px - 0.75rem);
    }

    .heading {
      color: #161616;

      &:hover,
      &:focus {
        text-decoration: none;
        color: $primary;
      }
    }

    .dropdown-item--subnav {
      width: 100%;
      border-right: 1px solid #e2e1df;
      padding: 0.75rem 1.5rem;
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(lg) {
        width: 300px;
      }

      @include media-breakpoint-up(xl) {
        width: 360px;
      }

      .media {
        padding: 0 0 20px;
      }

      span,
      b {
        white-space: normal;
      }

      @include media-breakpoint-down(md) {
        padding: $space-2xs 0;
        border-right: 0;
      }

      &:last-child {
        border-right: 0;
      }

      i.icon {
        width: $space-xl;
        height: $space-xl;
        margin-right: $space-sm;

        path,
        rect {
          stroke: $orange;
          transition: all 0.25s ease-out;
        }

        &:hover,
        &:focus {

          path,
          rect {
            stroke: $primary;
          }
        }
      }
    }

    b,
    span {
      display: block;
    }

    span {
      margin-top: $space-3xs;
      font-size: $font-size-sm;
      color: $body-color;
    }
  }

  .btn {
    margin-top: $space-sm;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
      margin-left: $navbar-nav-link-padding-x;
    }
  }
}

.navbar-nav {
  margin-left: auto;

  .nav-item {
    &--negative-margin {
      margin-right: -$space-sm;
    }
  }
}

.navbar-expand-lg>.container {
  padding-right: calc(#{$space-lg} / 2);
  padding-left: calc(#{$space-lg} / 2);
}

.navbar-brand {
  margin-right: 0;
  font-size: initial;

  path {
    fill: $orange;
  }

  .navbar--home:not(.navbar--scrolled) & {
    path {
      fill: $white;
    }
  }
}

.navbar-toggler {
  position: relative;
  width: 28px;
  height: 20px;
  border: 0;
  border-radius: 0;
  transform: rotate(0deg);
  transition: all 0.5s ease-out;

  &:focus {
    outline: none;
  }

  .navbar-toggler-icon {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: $dark;
    transition: all 0.25s ease-in-out;

    +.navbar-toggler-icon {
      margin: 0;
    }

    &:nth-child(1) {
      top: -1px;
      left: 4px;
      transform: rotate(45deg);
      transform-origin: 0;
    }

    &:nth-child(2) {
      left: -100%;
      width: 0;
      transform-origin: 0;
    }

    &:nth-child(3) {
      top: 19px;
      left: 4px;
      transform: rotate(-45deg);
      transform-origin: 0;
    }
  }

  &.collapsed {
    .navbar-toggler-icon {
      left: 0;
      transform: rotate(0deg);

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: 9px;
        width: 100%;
      }

      &:nth-child(3) {
        top: 18px;
      }
    }
  }
}

.navbar-collapse {
  @include media-breakpoint-down(md) {
    position: fixed;
    top: calc(#{$navbar-heightt} - 2px);
    left: 0;
    overflow-y: auto;
    width: 100%;
    height: calc(100vh - #{$navbar-height} - 2px);
    padding: 0 calc(#{$grid-gutter-width} / 2) $space-lg;
    background: $white;
    box-shadow: $box-shadow;
  }
}