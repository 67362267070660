.affiliates-how {
  padding-bottom: 0;

  &__title {
    margin-bottom: $space-2xl;

    span {
      position: relative;

      &::after {
        position: absolute;
        top: $space-md;
        left: calc(100% + #{$space-lg});
        width: 40px;
        height: 33px;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iMzMiIHZpZXdCb3g9IjAgMCA0MCAzMyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyIDEpIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxjaXJjbGUgZmlsbD0iI0Y5MkY5RSIgY3g9IjI5LjQiIGN5PSIyMy40IiByPSI4LjQiLz48ZyBzdHJva2U9IiMxNjE2MTYiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiIHN0cm9rZS13aWR0aD0iMyI+PHBhdGggZD0iTTguNC42djE2LjhNMTYuOCA5SDAiLz48L2c+PC9nPjwvc3ZnPg==');
        content: '';

        @include media-breakpoint-up(md) {
          left: -$space-3xl;
        }
      }
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}
