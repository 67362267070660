.press-resources-details {
  &__cover {
    @include aspect-ratio(1, 1);
    display: block;
    overflow: hidden;
    min-width: 194px;
    margin-right: $space-lg;
    background-color: $primary;
  }

  &__title {
    margin: $space-sm 0 $space-2xs;
  }

  &__position {
    color: $body-color;
  }

  &__actions {
    margin-top: $space-lg;
    font-weight: $font-weight-bold;
  }

  &__address {
    margin-top: $space-xl;

    p {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 0;
    }
  }
}
