.customer-desc-list {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;

  &__item {
    margin-bottom: $space-xl;
  }

  &__icon {
    margin-right: $space-lg;
  }

  &__title {
    margin-bottom: $space-sm;
  }
}
