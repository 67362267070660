.cookiealert {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 !important;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  transform: translateY(100%);
  transition: all 500ms ease-out;
  color: #ecf0f1;
  background: #f8f9fa;
  padding-right: calc(#{$space-lg} / 2);
  padding-left: calc(#{$space-lg} / 2);
  padding-top: 15px;
  padding-bottom: 15px;
}

.cookiealert.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0%);
  transition-delay: 1000ms;
}

.cookiealert .btnAccept {
  margin-left: 10px;
  vertical-align: baseline;
}