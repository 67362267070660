.testimonial {
  padding-top: $space-2xl;

  &__blockquote {
    margin-top: $space-sm;
  }

  &__author {
    display: block;
    margin: $space-lg 0;
    font-weight: $font-weight-normal;

    &__name {
      display: block;
      font-size: $font-size-md;
      color: $dark;
    }

    &__position {
      font-size: $font-size-base;
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: $space-lg;

    @include media-breakpoint-up(lg) {
      justify-content: flex-end;
      margin-top: 0;
    }
  }

  &__actions {
    margin: $space-xl 0;
    font-size: $font-size-md;
    font-weight: $font-weight-bold;
  }

  &--blue {
    background-color: $blue-light;
  }

  &--cream {
    background-color: $cream;
  }

  @include media-breakpoint-down(lg) {
    &--no-padding-top-down-lg {
      padding-top: 0;
    }
  }
}
