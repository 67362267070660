.jobs-list {
  margin: $space-md 0 $space-2xl;
  padding-left: 0;
  list-style-type: none;

  @include media-breakpoint-up(md) {
    margin-top: $space-xl;
  }

  &__item {
    margin: 0;
    border-bottom: 1px solid $gray-300;

    &:first-child {
      border-top: 1px solid $gray-300;
    }
  }

  &__title {
    a {
      display: block;
      position: relative;
      padding: $space-sm 0;
      padding-right: $space-xl;
      transform: translateY(1px);

      &:not(.collapsed) {
        color: $primary;
      }
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: $space-md;
    margin-top: -8px;
    font-size: $font-size-sm;
    transform: rotate(180deg);
    transition: all 0.25s ease-out;

    path {
      fill: $dark;
    }

    .collapsed & {
      transform: rotate(0);

      path {
        fill: $body-color;
      }
    }
  }

  &__body {
    padding: $space-md 0 $space-xl;

    p {
      margin-bottom: $paragraph-margin-bottom * 1.5;
    }

    ul {
      margin-bottom: $paragraph-margin-bottom * 1.5;
      padding-left: $space-3xs;
      list-style: none;

      > li {
        margin-bottom: calc(#{$paragraph-margin-bottom} / 2);
        position: relative;
        padding-left: $space-xs;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          content: '-';
        }
      }
    }
  }

  &__actions {
    margin-top: $space-xl;
  }
}
