.blog-news-list {
  margin-top: $space-xl;
  margin-bottom: $space-lg;
  padding-left: 0;
  list-style: none;

  &__item {
    margin-bottom: $space-lg;

    @include media-breakpoint-up(xl) {
      margin-bottom: 0;
    }
  }

  &__tags {
    margin-bottom: $space-3xs;
  }

  &__logo {
    margin-bottom: $space-lg;
  }

  &__title {
    margin-top: $space-md;
    line-height: $line-height-lg;

    @include media-breakpoint-up(md) {
      @include ellipsis(107px, 3);
    }

    a {
      @extend .link-dark;
    }

    &--lg {
      @include media-breakpoint-up(md) {
        @include ellipsis(125px, 3);
      }
    }
  }

  &__read-more {
    margin-top: $space-md;
    font-weight: $font-weight-bold;
  }
}
