.btn {
  position: relative;
  border-width: 2px;
  box-shadow: $box-shadow;

  &-xl {
    padding: $btn-padding-y-xl $btn-padding-x-xl;
    font-size: $btn-font-size-xl;
  }

  &.cta-btn {
    min-width: 200px;
    margin-top: 12px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    &:first-child {
      margin-right: 24px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 12px;
        margin-right: 0;
      }
    }
  }

  &.btn-aqua {
    background-color: #0CCCED;
  }

  svg {
    position: absolute;
    top: 50%;
    left: $space-xs + $space-3xs;
    transform: translateY(-50%);

    g {
      stroke: $gray-600;
      transition: stroke 0.25s ease-out;
    }
  }

  &-outline-dark {
    background-color: $white;

    &:hover,
    &:focus {
      svg {
        g {
          stroke: $white;
        }
      }
    }
  }

  &.download-btn {
    margin-top: 20px;
  }

  &--search {
    box-shadow: none!important;
  }
}
