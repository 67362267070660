.box {
  padding: $space-md;
  background-color: $white;
  border: 1px solid $gray-200;
  border-radius: $border-radius-lg;
  box-shadow: $box-shadow-lg;

  @include media-breakpoint-up(lg) {
    padding: $space-lg;
  }

  &--lg {
    padding: $space-lg;

    @include media-breakpoint-up(xl) {
      padding: $space-xl;
    }
  }

  &--blue {
    box-shadow: $box-shadow-lg-blue;
  }

  &--black {
    box-shadow: $box-shadow-lg-black;
  }
}
