.support {
  padding-top: $space-2xl;
  background-color: $cream;

  @include media-breakpoint-up(lg) {
    padding-top: $space-3xl;
  }

  &__title {
    margin-bottom: $space-2xl;

    @include media-breakpoint-up(lg) {
      margin-bottom: $space-3xl;
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    @include media-breakpoint-up(lg) {
      justify-content: flex-end;
    }

    @include media-breakpoint-up(xl) {
      padding-left: $space-xl;
    }
  }
}
