.checks-list {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  color: $dark;

  &__item {
    position: relative;
    padding-left: $space-lg;

    &:not(:last-child) {
      margin-bottom: $space-2xs;
    }
  }

  &__check {
    position: absolute;
    top: -3px;
    left: 0;

    &--orange path { fill: $orange; }
    &--green path { fill: $success; }
    &--blue path { fill: $blue-capri; }
  }

  &.gray {
    color: $secondary;
  }
}
