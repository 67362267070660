.brand-assets {
  &__title {
    margin-bottom: $space-md;
  }

  &__actions {
    margin-top: $space-lg;
  }

  &__image {
    margin-top: $space-xl;
  }
}
