@mixin aspect-ratio($w, $h) {
  $ratio: calc(#{$h} / #{$w});
  position: relative;

  &::before {
    display: block;
    padding-top: calc(100% * #{$ratio});
    content: '';
  }

  > img,
  > picture {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
